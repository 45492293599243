import { useShowPasswordButton } from 'components/Forms/useShowPasswordButton'
import { signInWithEmailAndPassword } from 'firebase/auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { auth } from '../../clients/firebase-client'
import Button from '../Button'
import LoadingIcon from '../Button/LoadingIcon'

export default function EmailLoginForm() {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')

	const { EyeballButton, toggleShow, showPassword } = useShowPasswordButton()
	const router = useRouter()

	async function handleSignin(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()
		setLoading(true)
		try {
			await signInWithEmailAndPassword(auth, email, password)
			setTimeout(() => {
				setLoading(false)
				if (localStorage.getItem('returnTo')) {
					const returnTo = localStorage.getItem('returnTo')
					localStorage.removeItem('returnTo')
					router.push(`${returnTo}`)
				} else router.push('/')
			}, 1000)
		} catch (err: any) {
			setLoading(false)
			if (err.code === 'auth/wrong-password') {
				setError('Incorrect email or password. Please try again.')
			} else if (err.code === 'auth/user-disabled') {
				setError('This account is not active. Please contact your Account Manager.')
			} else if (err.code === 'auth/user-not-found') {
				setError('Incorrect email or password. Please try again.')
			} else if (err.code === 'auth/invalid-email') {
				setError('Email cannot be blank.')
			} else {
				setError(err.code)
			}
		}
	}

	return (
		<form onSubmit={handleSignin} className="w-full">
			<div className="mb-6">
				<label htmlFor="email" className="mb-1 block font-medium">
					Email
				</label>
				<input
					type="email"
					name="email"
					id="email"
					className="w-full rounded border-slate-200"
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
			<div className="mb-6">
				<label htmlFor="password" className="mb-1 block font-medium">
					Password
				</label>
				<div className="relative">
					<input
						type={showPassword ? 'text' : 'password'}
						name="password"
						id="password"
						className="w-full rounded border-slate-200"
						onChange={(e) => setPassword(e.target.value)}
					/>
					<EyeballButton showPassword={showPassword} onClick={toggleShow} />
				</div>
			</div>
			{error && <p className="my-4 text-red-400">{error}</p>}
			<Button full>{loading ? <LoadingIcon /> : 'Login'}</Button>
			<div className="mt-4 flex items-center justify-center gap-3">
				<div>
					<span>Don&#39;t have an account? </span>
					<Link href="/signup">
						<a className="pl-1 text-ab-100">Sign up</a>
					</Link>
				</div>
				<span>|</span>
				<div className="">
					<Link href="/forgot-password">
						<a className="text-ab-100">Forgot password?</a>
					</Link>
				</div>
			</div>
		</form>
	)
}

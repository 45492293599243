import LoadingOverlay from 'components/LoadingOverlay'
import SignupLoginBanner from 'components/Signup/SignupLoginBanner'
import { useSignupType } from 'components/Signup/useSignupType'
import { loadingMessages } from '../constants'
import { handleMicrosoftSignup } from 'functions/auth/handleMicrosoftSignup'
import Head from 'next/head'
import Image from 'next/image'
import { useState } from 'react'
import LoginForm from '../components/Forms/Login'
import { wait } from 'utils/wait'
import { randomIntFromRange } from 'utils/randomIntFromRange'

export default function LoginPage() {
	const { type, setType, SignupTypeToggle } = useSignupType()

	const [loading, setLoading] = useState(false)
	const [loadingMessage, setLoadingMessage] = useState('')

	async function handleEmployeeLogin() {
		setLoading(true)
		const messageSwap = setInterval(() => {
			setLoadingMessage(loadingMessages[randomIntFromRange(0, 260)])
		}, 4000)
		handleMicrosoftSignup().then(() => {
			clearInterval(messageSwap)
			setLoading(false)
		})
	}

	return (
		<>
			<Head>
				<title>Login to ARC Quotes</title>
			</Head>
			<div className="flex h-screen bg-white">
				<SignupLoginBanner />
				<div className="flex w-full items-center px-4 lg:w-7/12 lg:px-0">
					<div className="mx-auto w-full max-w-lg">
						<div className="mb-4 flex justify-center lg:mb-10">
							<Image src="/images/arc-logo-red-tagline.svg" height={100} width={200} alt="arc logo" />
						</div>
						<div className="flex justify-center">
							<SignupTypeToggle type={type} setType={setType} />
						</div>
						<div className="flex flex-col items-center justify-center">
							{type === 'customer' ? (
								<div className="flex w-full flex-col items-center">
									<h1 className="mb-4 mt-6 text-4xl font-black text-ab-100 lg:mb-10">Login</h1>
									<LoginForm />
								</div>
							) : (
								<button
									onClick={handleEmployeeLogin}
									className="flex items-center gap-4 rounded border px-10 py-4 hover:bg-slate-100"
									style={{ minWidth: 280 }}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" height={20}>
										<path fill="#fa6736" d="M4.7 5.8h42.2V48H4.7z" />
										<path fill="#00b6ef" d="M4.7 52.8h42.2V95H4.7z" />
										<path fill="#8fc13f" d="M52.1 5.8h42.2V48H52.1z" />
										<path fill="#ffc342" d="M52.1 52.8h42.2V95H52.1z" />
									</svg>
									Login with ARC Email
								</button>
							)}
						</div>
					</div>
				</div>
				<LoadingOverlay message={loadingMessage} dark loading={loading} />
			</div>
		</>
	)
}
